import React from 'react'
import TopBar from '../components/TopBar';
import Scanner3 from '../components/queue/Scanner3';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { createUserAction, getOTp } from '../redux/actions/userActions';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { baseUrl } from '../redux/actions/baseUrl';
import { toast } from 'react-toastify';
import Loading from '../components/Loading';

export default function QrRegister() {
    const [start, setStart] = React.useState(false)
    const [scanResult, setScanResult] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const store = useSelector((state) => state);
    const {success, error, data } = store.createUser;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    console.log('data', data)


    const verify_qr = async (data, type) => {
        //   make an API call to verify barcode data
        try {
            // let res = await axios.get(`${baseUrl}/check_barcode?qr_code=${data}&type=${type}`);
            const res = await axios.get(`${baseUrl}/check_barcode?qr_code=${encodeURIComponent(data)}&type=${encodeURIComponent(type)}`);
            console.log('res', res)
            if (res.data.status === 'success') {
                console.log('data', res.data)
                // setLoading(true);
                dispatch(createUserAction(res.data.patient.system_id));
            }
        } catch (err) {
            setLoading(false);
            toast.error('Invalid QR Code')
            console.log('err', err)
            return false;
        }
    }

    const startStop = () => {
        setStart(!start)
    }
    const handleScanResult = async (data) => {
        let trimmed_data = data.replace(/^(https?:\/\/)/i, '');
        setScanResult(trimmed_data);
        try {
            setLoading(true);
            toast.warning('Please wait while we verify your QR Code...', {
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            await verify_qr(trimmed_data, 'register');

        } catch (err) {
            setLoading(false);
            toast.error('Invalid QR Code');
            console.log('err', err);
        }
    };
    useEffect(() => {
        if (success) {
            toast.success('QR Code verified successfully');
            dispatch(getOTp(data.file_number, data.phone_number));
            navigate("/success", { state: data });
        }
        if (error) {
            setLoading(false);
            toast.error('Something went wrong, please try again or you already have an account, you can login with QR', {
                autoClose: 8000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            navigate('/login');
        }
        return () => {
            dispatch({ type: 'CREATE_USER_RESET' })
        };
    }, [success, error]);
    return (
        <div className='h-screen'>
            <TopBar title={'QR Code Scanner'} url={-1} />

            <div className='flex justify-center h-full'>
                <div className='bg-white rounded-lg'>
                    {/* <div className='text-center shadow-md w-full'>
            <h1 className='text-2xl font-bold '>Scan QR Code</h1>
            <p className='mt-2'>
              Scan the QR code to login/continue.
              <br />
              <br /> 
            </p>
          </div> */}
                    {
                        loading ? <Loading /> :
                            <div className="flex flex-col items-center justify-center mt-10">
                                <button onClick={startStop} className="bg-primary100 text-white p-2 rounded-md w-3/6 mb-4">
                                    {start ? "Stop" : "Start"}
                                    {start ? <i className="fa fa-stop ml-2"></i> : <i className="fa fa-play ml-2"></i>}
                                </button>
                                {
                                    start ?
                                        <Scanner3
                                            setStart={setStart}
                                            start={start}
                                            type={'register'}
                                            onScanResult={handleScanResult}

                                        /> :
                                        <img className="mt-2" src="./barcode.png" alt="" />
                                }
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}
