import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    // Your Firebase project configuration details
    apiKey: "AIzaSyDha7Sqn-gGfkIQHisdYoZI3c-V0lQ7CZc",
    authDomain: "misas-app.firebaseapp.com",
    projectId: "misas-app",
    storageBucket: "misas-app.appspot.com",
    messagingSenderId: "659133535184",
    appId: "1:659133535184:web:e93e941073980071289fd5",
    measurementId: "G-ERKLW58V2V"
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
