import React, { useEffect, useState, useRef } from 'react';
import { MessageList, Input, Button } from 'react-chat-elements';
import TopBar from '../../components/TopBar';
import { firestore } from './firebaseConfig';
import { AiOutlineSend } from 'react-icons/ai';
import Loading from '../../components/Loading';
import { check_token } from '../../utils';

const ForwardedMessageList = React.forwardRef((props, ref) => (
    <MessageList {...props} forwardedRef={ref} />
));

export default function Chat() {
    const messageListRef = useRef();
    const containerRef = useRef();
    const audioRef = useRef();

    const [isLoading, setIsLoading] = useState(true);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isPlaying, setPlaying] = useState(false);
    const type = JSON.parse(localStorage.getItem('type'));
    const receiver = type == 'Hospital' ? localStorage.getItem('chat_receiver').toString() :JSON.parse(localStorage.getItem('hospital_name'));
    const sender = type == 'Hospital' ? JSON.parse(localStorage.getItem('hospital_name')) : JSON.parse(localStorage.getItem('userInfo'))?.firstname;
    const sender_id = type == 'Hospital' ? JSON.parse(localStorage.getItem('hospital_name')) : JSON.parse(localStorage.getItem('userInfo'))?.id.toString();

    useEffect(() => {
        check_token();
        const unsubscribe = firestore
            .collection('messages')
            .where('sender', 'in', [sender_id, receiver])
            .where('receiver', 'in', [receiver, sender_id])
            .orderBy('timestamp')
            .limit(100)
            .onSnapshot((snapshot) => {
                const updatedMessages = snapshot.docs.map((doc) => doc.data());
                setMessages(updatedMessages);
                console.log('updatedMessages', updatedMessages)
                scrollToBottom();
                playNotificationSound();
            });

        if (messages?.length === 0) {
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }

        return () => unsubscribe();
    }, []);

    const scrollToBottom = () => {
        if (containerRef.current) {
            const container = containerRef.current;
            container.scrollTop = container.scrollHeight;
        }
    };

    useEffect(() => {
        scrollToBottom();
    }, [messages]);

    const playNotificationSound = () => {
        if (!isPlaying && audioRef.current) {
            setPlaying(true);
            audioRef.current.play();
            audioRef.current.onended = () => setPlaying(false);
        }
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        try {
            await firestore.collection('messages').add({
                text: newMessage,
                sender: sender_id,
                receiver: receiver,
                sender_name: sender,
                timestamp: new Date(),
            });
            setNewMessage('');
        } catch (error) {
            console.error('Failed to send message', error);
        }
    };

    return (
        <div className="h-screen" style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
                <TopBar url={-1} title={type == 'Hospital' ? 'Chat' : receiver} />
            </div>
            <div className="mb-12" style={{ flex: 1, overflowY: 'auto' }} ref={containerRef}>
                {messages.length === 0 ? (
                   isLoading ?  <Loading /> : <p className="text-center text-gray-500"> <AiOutlineSend className="inline-block mr-2" /> <span className="inline-block"> No messages </span > You don't have any messages </p>
                ) : (
                    <ForwardedMessageList
                        className="message-list"
                        lockable={true}
                        dataSource={messages.map((message) => ({
                            position: message.sender === sender_id ? 'right' : 'left',
                            type: 'text',
                            className: message.sender === sender_id ? '#087F4C' : '#EEEEEE',
                            title: message.sender === sender_id ? sender : message.sender_name?? 'Name',
                            text: message.text,
                            date: message.timestamp.toDate(),
                        }))}
                        ref={messageListRef}
                    />
                )}
            </div>
           
            <div className="fixed bottom-0 left-0 right-0 bg-white m-2" style={{ display: 'flex' }}>
                <input
                    placeholder="Type here..."
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSendMessage();
                        }
                    }}
                    multiline={true}
                    style={{ flex: '1', minHeight: '30px', resize: 'none', marginRight: '8px', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
                />
                <Button
                    text="Send"
                    onClick={handleSendMessage}
                    title="Send"
                    backgroundColor="#087F4C"
                    style={{ minWidth: '100px' }}
                />
            </div>

            <audio ref={audioRef} src="/audio/msg.wav" preload="auto" />
        </div>
    );
}

