import React, { useState } from "react";
import TopBar from "../TopBar";
import { ImCheckmark } from "react-icons/im";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../redux/actions/baseUrl";
import Loading from "../Loading";

export default function QueueWait() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate();
    const [show, setShow] = useState();
    const [checked, setChecked] = useState();
    // setTimeout(() => {
    //     setShow(false);
    // }, 5000);

    const getCheckedIn = async () => {
        try {
            const res = await axios.get(`${baseUrl}/get_checked_in?patient=${user.id}`, {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
                },
            });
            console.log('checkedin', res.data);
            setChecked(res.data);
        } catch (err) {
            navigate('/homedashboard');
            console.log(err);
        }
    } 

    useEffect(() => {
        getCheckedIn();

        const intervalId = setInterval(() => {
            getCheckedIn();
        }, 10000); 

        return () => {
            clearInterval(intervalId); 
        };
    }, []);

    return (
        <div className="h-screen">
            <TopBar title={"Queue"} url={'/queue-barcode'} />

            {checked ?
                <div
                    className="bg-primary100 max-h-[32rem] m-5 rounded-lg text-white"
                    style={{ borderRadius: "20px" }}
                >
                    <div className="flex flex-col items-center justify-center mt-14">
                        <div className="flex justify-center items-center mt-8">
                            <div
                                className="mx-2 mt-2"
                                style={{ height: "2px", width: 30, backgroundColor: "white" }}
                            ></div>
                            <h1 className="font-medium text-2xl mt-2">Queue Number</h1>
                            <div
                                className="mx-2 mt-2"
                                style={{ height: "2px", width: 40, backgroundColor: "white" }}
                            ></div>
                        </div>
                        <div className="flex flex-col items-center justify-center">
                            <h1 className="font-bold text-5xl">{checked.checked_in?.queuenum}</h1>
                            <p className="p-2">Wating: {checked?.waiting}</p>
                        </div>
                        <div>
                            <p className="p-2 pb-8">
                                You have 00:12:00 mins to reach the doctor
                            </p>
                        </div>
                    </div>
                </div>
            
                :
                <Loading/>
            }
                
                <div className=" flex flex-col-reverse justify-start items-center  h-3/6">
                    <button onClick={() => navigate('/homedashboard')} className="flex justify-center items-center py-2 w-80 bg-white rounded-md text-primary100 text-[20px] outline outline-primary100 outline-2 mb-8">
                        Back to Home
                    </button>
                </div>
            
         
                {/* <div
                    className="shadow-inner shadow-black"
                    style={{ borderRadius: "20px" }}>
                    <div className="flex justify-center items-center mt-[3rem]">
                        <div className="w-[120px] h-[120px] bg-primary100 flex justify-center items-center rounded-full mt-4">
                            <ImCheckmark className="text-white text-[3rem]" />
                        </div>
                    </div>
                    <p className="text-[2rem] flex justify-center items-center mt-[1rem]">
                        You are next!
                    </p>
                    <p className="text-ash100 flex justify-center items-center  px-10">
                        Please proceed to see the doctor.
                    </p>

                    <div className="flex justify-center items-center">
                        <button onClick={navigate('/queue')} className="font-bold w-full py-3 bg-primary100 rounded-md text-white text-[22px] mx-5 mt-[2rem]">
                            OK
                        </button>
                    </div>
                </div> */}
        
        </div>
    );
}
