import React, { useEffect, useState } from "react";
import weather from "../assets/weather.png";
import doctorp from "../assets/doctorp.png";
import drpassport from "../assets/doc.jpg";
import clinic from "../assets/clinic.jpg";
import { BsBellFill, BsFillCalendar2DateFill } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { AiFillClockCircle, AiFillWechat, AiOutlineFile } from "react-icons/ai";
import { FiLogOut } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import BottomNav from "./BottomNav";
import AppointmentCard from "./Appointment/AppointmentCard";
import { getAppointmentAction, getClinicsAction, getDoctorsAction } from "../redux/actions/appointmentActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loading from "./Loading";
import { logoutUserAction } from "../redux/actions/userActions";
import { toast } from "react-toastify";
import { check_token } from "../utils";
import { AddToHomeScreen } from "./AddToHomeScreen";
import { baseUrl } from "../redux/actions/baseUrl";

function HomeDashboard() {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const [notifications, setNotifications] = useState(
    JSON.parse(localStorage.getItem("notifications"))
  );

  const dispatch = useDispatch();
  const store = useSelector((state) => state.getAppointmentAction);
  const docStore = useSelector((state) => state.getDoctorsAction);
  const logout = useSelector((state) => state.logOutUser);
  // console.log('log', logout)
  const clickHandler = () => {
    setActive(!active);
  };


  const handleLogout = () => {
    dispatch(logoutUserAction());
  };

  const requestPermission = () => {
    //ask for permission to use windows Notifications
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
    //if permission is denied, show a message to the user
    if (Notification.permission === "denied") {
      Notification.requestPermission();
    }
    //if permission is not granted ask for permission
    if (Notification.permission !== "granted") {
      Notification.requestPermission();
    }
  };

  useEffect(() => {
    check_token();
    dispatch(getClinicsAction())
    setUpcomingAppointments(store?.data?.upcoming);
    if (!store.success) {
      dispatch(getAppointmentAction(JSON.parse(localStorage.getItem("userInfo"))?.id));
    }
    // requestPermission();
    if (logout.success) {
      navigate('/login');
      toast.success(logout.data.message);
      window.location.reload();
    }
  }, [upcomingAppointments, logout.success]);


  return (
    <div className="h-auto flex flex-col justify-center items-center">
      <div className="w-full md:w-[590px] h-auto md:h-auto relative rounded-md  shadow-xl">
        <div className="w-full h-[328px] bg-primary100 rounded-b-[5rem] relative">
          <div className="flex justify-between p-5">
            <div className="">
              <div className="flex">
                <span className="text-[1.5rem] text-white">
                  {
                    // greeting based on time of the day
                    new Date().getHours() < 12
                      ? `Good Morning ${JSON.parse(localStorage.getItem("userInfo"))
                        ?.firstname
                      }`
                      : new Date().getHours() < 18
                        ? `Good Afternoon ${JSON.parse(localStorage.getItem("userInfo"))
                          ?.firstname
                        }`
                        : `Good Evening  ${JSON.parse(localStorage.getItem("userInfo"))
                          ?.firstname
                        }`
                  }
                </span>
              </div>
              <p className="text-[2rem] text-white mt-2 font-bold">
                {JSON.parse(localStorage.getItem("hospital_name"))}
              </p>
            </div>
            <div className="flex justify-between">
              <Link to={"/notification"} className="relative top-[-1.5rem]">
                {
                  // notification badge
                  notifications != false ? (
                    <span className="translate-x-2/4 -translate-y-1/2 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 p-1.5 py-0  text-xs bg-red-600 rounded-full z-10"></span>
                  ) : (
                    <span className="translate-x-2/4 -translate-y-1/2 rotate-0 skew-x-0 skew-y-0 scale-x-100 scale-y-100 p-1.5 py-0  text-xs rounded-full z-10"></span>
                  )
                }
                <BsBellFill className="text-white text-[2rem]" title="Notifications" />

              </Link>
              <HiOutlineDotsVertical
                className="text-white text-[1.5rem] cursor-pointer"
                onClick={clickHandler}
                size={40}
              />
              {active ? (
                <div className="flex flex-col">
                <p onClick={handleLogout}
                  className="text-white text-[1rem] cursor-pointer">
                    <FiLogOut
                      size={40}
                    className="text-white text-[1.5rem]"
                      title="Log Out" />
                </p>
                  
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        
          <div className="w-[90%] h-[177px] bg-white absolute bottom-[-5rem] right-[1.5rem] rounded-md shadow-lg">
            <div className="flex justify-between items-center px-4">
              <div className="w-[300px] ">
                <p className="text-[16px] font-normal">
                  You can now schedule appointment with us directly
                  Online !
                </p>
                <Link to="/first-appointment">
                  <button className="p-2 rounded-lg bg-primary100 text-white mt-7 shadow-lg">
                    Start Now
                  </button>
                </Link>
              </div>
              <div>
                <img src={doctorp} height="177px" width="100px" />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[4rem] p-4">

        </div>
        <div>
          {/* <AddToHomeScreen /> */}

          <div className="flex justify-between items-center p-4 shadow border-2">
            <p className="text-[20px] font-bold mb-2 ml-4"> <Link to={'/chats'}>Message Us </Link></p>
            <Link to={'/chats'}>
              <AiFillWechat size={80} color="#087F4C"/>
            </Link>
          </div>
          <div className="p-4 shadow m-2 border-2 border-primary100">
            <Link to={'/lab_results'} className="flex justify-between items-center">
            <p className="text-[20px] font-bold mb-2">View Recent Lab results</p>
              <AiOutlineFile size={40} color="#087F4C"/>
            </Link>
          </div>
         
          <div className="border-2 shadow mt-2">
            <p className="text-[20px] font-bold mb-1 ml-8 mt-3 ">
              My Activities
            </p>
            <div className="flex justify-between">
              <div className="flex flex-col justify-center items-center border-2 shadow-xl shadow-black-600  p-4 rounded-md m-2 w-48">
                <p className="font-semibold text-md">No. of Visits</p>
                <p className="text-primary100 font-bold text-4xl">
                  {store?.data?.visit_count?? 0}
                </p>
              </div>
              <div className="flex flex-col justify-center items-center border-2 shadow-xl shadow-black-600  p-4 rounded-md m-2 w-48">
                <p className="font-semibold text-md">No. of Appointments</p>
                <p className="text-primary100 font-bold text-4xl">
                  {store?.data?.appt_count?? 0}
                </p>
              </div>
            </div>
          </div>
          <div className="flex justify-between p-4">
            <p className="text-[20px] font-bold mb-2 ml-4">
              Upcoming Appointment
            </p>
            <p className="text-[18px] text-primary100">
              <Link to={"/appointment"}>View all</Link>
            </p>
          </div>
          <div className="-mt-5 mb-20">
            {upcomingAppointments?.length > 0 ? (
              upcomingAppointments
                .slice(0, 2)
                .map((appointment) => (
                  <AppointmentCard
                    key={appointment.id}
                    pics={clinic}
                    name={appointment.clinic_name}
                    post={"Clinic"}
                    date={appointment.appt_date}
                    time={appointment.appt_time}
                    mode={appointment.appt_type}
                  />
                ))
            ) : (
              <div className="flex justify-center items-center">
                <p className="text-ash100 text-lg">No upcoming appointment</p>
              </div>
            )}
          </div>
        </div>
        <BottomNav color={"text-primary100"} />
      </div>
    </div>
  );
}

export default HomeDashboard;
