import React, { useEffect } from 'react';
import { PaystackButton } from 'react-paystack';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { createPayment } from '../../redux/actions/payment';
import { bookAppointmentAction } from "../../redux/actions/appointmentActions";
import { toast } from 'react-toastify';

export default function Paystack({ amount, email, phone, notes, url, compData }) {
    const dispatch = useDispatch();
    const { loading, success, data } = useSelector((state) => state.createPayment);
    const appt = useSelector((state) => state.bookAppontment);
    console.log('appt', appt)

    let user = JSON.parse(localStorage.getItem('userInfo'));
    // console.log('create payment', success, data, loading)

    const navigate = useNavigate();
    const [values, setValues] = React.useState({
        amount: amount,
        email: email,
        phone: phone,
        notes: notes
    })
    const clearValues = () => {
        setValues({
            amount: '',
            email: '',
            phone: '',
            notes: ''
        })
    }
    const config = {
        reference: (new Date()).getTime(),
        email: email,
        amount: amount * 100,
        publicKey: 'pk_test_ca5483b1ebbff03f56af48ca9e744bd36d10e50c',
    };

    // you can call this function anything
    const handlePaystackSuccessAction = (reference) => {
        dispatch(createPayment(user.id, amount, reference.reference, user.location, notes))
        // Implementation for whatever you want to do with reference and after success call.
        console.log(reference);
        console.log('values', values)
    };

    // you can call this function anything
    const handlePaystackCloseAction = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
        toast.error('Payment failed', {
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const componentProps = {
        ...config,
        text: 'Pay Now',
        onSuccess: (reference) => handlePaystackSuccessAction(reference),
        onClose: handlePaystackCloseAction,
    };

    useEffect(() => {
        if (success) {
            toast('Successful !', {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            if (compData) {
                if (!appt.success) {
                    dispatch(bookAppointmentAction(compData));
                }

                if (appt.success) {
                    navigate(`/${url}`, { state: { data: compData } })
                    window.location.reload()
                }
            }
        }
        return () => {
            clearValues()
        }
    }, [success, appt.success])

    return (

        <PaystackButton className='bg-primary100 text-white text-xl text-bold mt-5 px-16 py-3 rounded-xl' {...componentProps} />

    )
}
