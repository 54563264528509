import React, { useEffect, useState } from 'react';
import { ChatList, MessageBox } from 'react-chat-elements';
import { firestore } from './firebaseConfig';

const MessagingComponent = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');

    useEffect(() => {
        const unsubscribe = firestore
            .collection('messages')
            .orderBy('timestamp')
            .onSnapshot((snapshot) => {
                const updatedMessages = snapshot.docs.map((doc) => doc.data());
                setMessages(updatedMessages);
            });

        return () => unsubscribe();
    }, []);

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        try {
            await firestore.collection('messages').add({
                text: newMessage,
                sender: 'CUSTOM_USER_ID', // Replace with your custom user ID
                timestamp: new Date(),
            });

            setNewMessage('');
        } catch (error) {
            console.error('Failed to send message', error);
        }
    };

    return (
        <div>
            <ChatList
                dataSource={messages.map((message) => ({
                    avatar: 'URL_TO_AVATAR',
                    alt: 'Avatar',
                    title: message.sender,
                    subtitle: message.text,
                    date: message.timestamp.toDate(),
                }))}
            />

            <MessageBox
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onSend={handleSendMessage}
                placeholder="Type a message..."
                showEmoji
            />
        </div>
    );
};

export default MessagingComponent;
