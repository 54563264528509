import React, { useEffect, useState } from "react";
import BottomNav from "../BottomNav";
import TopBar from "../TopBar";
import { BsFillPauseFill, BsFillPlayFill } from "react-icons/bs";
import Scanner3 from "./Scanner3";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../redux/actions/baseUrl";
import { check_token } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getClinicsAction } from "../../redux/actions/appointmentActions";
import Loading from "../Loading";
import Button from "../../components/Button/Button";

export default function QueueBarcode() {
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const clinicStore = useSelector((state) => state.getClinicsAction);
    console.log('clinicStore',clinicStore.data);
    
    const [start, setStart] = useState(false)
    const [scanResult, setScanResult] = React.useState('');
    const [loading, setLoading] = useState(false);
    const [showScanner, setShowScanner] = useState(true);
    const [clinics, setClinics] = useState([]);
    const [purpose, setPurpose] = useState([]);
    console.log('clini', clinics);
    console.log('purpose', purpose)
    const [state, setState] = useState({
        patient: user.id,
        location: user.location,
        purpose: '',
        clinic_id: '',
    });


    const startStop = () => {
        setStart(!start)
    }

    const get_purposes = async () => {
        try {
            const res = await axios.get(`${baseUrl}/get_purposes`, {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
                },
            });
            console.log('res', res)
            if (res.data.status === 'success') {
                setPurpose(res.data?.purposes)
            }
        } catch (err) {
            console.log('err', err)
        }
    
     }

    const self_check_in = async (data, type) => {
        //   make an API call to verify barcode data
        try {
            const res = await axios.get(`${baseUrl}/self_check_in?qr_code=${encodeURIComponent(data)}&type=${encodeURIComponent(type)}&patient=${user.id}`, {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
                },
            });
            console.log('res', res)
            if (res.data.status === 'success') {
                toast.success('Verified Successfully');
                setShowScanner(false);
                console.log('data', res.data)
                return;
            }
            if (res.data.status === 'already_checked_in') {
                toast.warning('Already Checked In');
                navigate('/queue-wait');
                return;
            }
        } catch (err) {
            toast.error('Invalid QR Code')
            console.log('err', err)
            return false;
        }
    }

    const handleScanResult = async (data) => {
        if (data) {
            console.log('data', data);
            setScanResult(data);
            try {
                setStart(!start);
                toast.warning('Please wait while we verify your QR Code...', {
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                await self_check_in(data, 'check_in');

            } catch (err) {
                toast.error('Invalid QR Code');
                console.log('err', err);
            }
        }
    };

    const handleChange = (e) => {
        if (e.target.name === "clinic") {
            setState({ ...state, clinic_name: e.target.options[e.target.selectedIndex].text, clinic_id: e.target.value })
            return;
        }
        setState({ ...state, [e.target.name]: e.target.value });
        console.log('state', state);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const res = await axios.post(`${baseUrl}/check_in`, state, {
                headers: {
                    "Content-Type": "Application/json",
                    "Authorization": "Bearer " + window.atob(JSON.parse(localStorage.getItem('btn_mi_sas'))),
                },
            });
            console.log('res', res)
            if (res.data.status === 'success') {
                toast.success('Checked In Successfully');
                setShowScanner(false);
                setLoading(false);
                navigate('/queue-wait');
            }
        } catch (err) {
            toast.error('Something went wrong');
            console.log('err', err);
            setLoading(false);
        }
    
     }

    useEffect(() => {
        check_token();
        get_purposes();
        if (!clinicStore.success) {
            dispatch(getClinicsAction());
        }
        setClinics(clinicStore?.data?.clinics);

    }, [clinicStore.success,purpose?.length]);

    return (
        <div className="h-screen">
            <TopBar title={"Queue"} url={'/homedashboard'} />

            {
                showScanner ?
                    <>
            <div className="flex flex-col items-center justify-center mt-12">
                <p className="text-xl font-medium">
                    Please place the QRcode inside the area
                </p>
                <div>
                    <p className="mt-3 text-center">Click "Start" to initiate automatic scanning and "Stop" to terminate the scanning process.</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center mt-10">
                <button onClick={startStop} className="bg-primary100 text-white p-2 rounded-md w-3/6 mb-4">
                    {start ? "Stop" : "Start"}
                    {start ? <i className="fa fa-stop ml-2"></i> : <i className="fa fa-play ml-2"></i>}
                </button>
                {
                    start ?
                        <Scanner3
                            setStart={setStart}
                            start={start}
                            onScanResult={handleScanResult}
                        /> :
                        <img className="mt-2" src="./barcode.png" alt="" />

                }
            </div>
            </>        :

                    <div className="flex flex-col items-center justify-center w-full h-3/6">
                        <div className="mt-4 w-5/6">
                            <label className="font-medium text-xl">Select Clinic</label>
                            <select
                                className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                                name="clinic"
                                onChange={handleChange}
                                value={state.clinic_id}
                            >
                                <option value="">Select Clinic</option>
                                {clinics?.length > 0 ? clinics?.map((item, index) => {
                                    return (
                                        <option key={index} value={
                                            item.id
                                        }>{item.name}</option>
                                    )
                                }) : <option value="">loading....</option>}
                            </select>
                        </div>
                        <div className="mt-4 w-5/6">
                            <label className="font-medium text-xl">Select Purpose</label>
                            <select
                                className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                                name="purpose"
                                onChange={handleChange}
                                value={state.purpose}
                            >
                                <option value="">Select Purpose</option>
                                {purpose?.length > 0 ? purpose?.map((item, index) => {
                                    return (
                                        <option key={index} value={
                                            item.id
                                        }>{item.name}</option>
                                    )
                                }) : <option value="">loading....</option>}
                            </select>
                        </div>

                        <div className="mt-5 mb-5 w-5/6">
                            {loading ? <Loading /> :
                                <Button text={"Submit"} width={"full"} bg={'bg-primary100'} textColor={'text-white'} handleClick={handleSubmit} />
                            }

                        </div>
                    </div>
            }
            <BottomNav color2={'text-primary100'} />
        </div>
    );
}
