import React, { useEffect } from "react";
import Button from "../../components/Button/Button";
import TopBar from "../../components/TopBar";
import { BiPhoneCall } from "react-icons/bi"
import { FcVideoCall } from "react-icons/fc"
import { Link, useNavigate } from "react-router-dom";
import { bookAppointmentAction, getClinicsAction } from "../../redux/actions/appointmentActions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loading from "../../components/Loading";
import { check_token, formatter, pushNotification } from "../../utils";
import { toast } from "react-toastify";

export default function Online() {
    const dispatch = useDispatch(); 
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("userInfo"));
    const clinicStore = useSelector((state) => state.getClinicsAction);
    const { loading, success } = useSelector((state) => state.bookAppontment);
    const [selectVideo, setSelectVideo] = React.useState(false)
    const [selectAudio, setSelectAudio] = React.useState(false)

    const [state, setState] = React.useState({
        appt_date: "",
        appt_time: "",
        clinic_name: "",
        clinic_id: "",
        notes: "",
        amount: 0,
        patient: user.id,
        location: user.location,
        type: "Appointment",
        appt_type: 'online',
        appt_mode: '',
        appt_url: `https://meet.jit.si/Promise-${JSON.parse(localStorage.getItem("userInfo"))?.firstname}${JSON.parse(localStorage.getItem("userInfo"))?.lastname}${JSON.parse(localStorage.getItem("userInfo")).id}`.replace(/\s+/g, '')
    });
    const [selected, setSelected] = React.useState("")
    const [amount, setAmount] = React.useState(0)
    const [clinic, setClinic] = React.useState([])
    let audio_amount = JSON.parse(localStorage.getItem('consultation_audio'))?? 2500;
    let video_amount = JSON.parse(localStorage.getItem('consultation_video'))?? 5000;

    const handleChange = (e) => {
        if (e.target.name === "clinic") {
            setState({ ...state, clinic_name: e.target.options[e.target.selectedIndex].text, clinic_id: e.target.value })
            return;
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const handleAudio = () => {
        setSelectAudio(true)
        setSelectVideo(false)
        setState({ ...state, amount: audio_amount, appt_mode: 'audio' })
    }
    const handleVideo = () => {
        setSelectVideo(true)
        setSelectAudio(false)
        setState({ ...state, amount: video_amount, appt_mode: 'video' })
    }

    const msg = (text) => {
        toast.warning(text, {
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (state.clinic_name === "") {
            msg("Please select a clinic");
            return;
        }
        if (state.appt_date === "") {
            msg("Please select an appointment date");
            return;
        }
        if (state.appt_time === "") {
            msg("Please select an appointment time");
            return;
        }
        if (state.amount === 0) {
            msg("Please select a mode of consultation");
            return;
        }
        // pushNotification('You have successfully booked an online appointment')
        // dispatch({ type: "BOOK_APPOINTMENT_RESET" });
        dispatch({ type: "GET_APPOINTMENT_RESET" });
        navigate("/appointment-payment", { state: { data: state } });
    };

    useEffect(() => {
        check_token();
        if (success) {
        }
        dispatch(getClinicsAction())
        setClinic(clinicStore?.data?.clinics)
        // return () => {
        //     dispatch({ type: "BOOK_APPOINTMENT_RESET" });
        //     dispatch({ type: "GET_APPOINTMENT_RESET" });
        // };
    }, [success])


    return (
        <div className="h-screen ">
            <TopBar title={"Appointment"} url={'/first-appointment'} />
            <div className="flex justify-between mt-5 mx-5">
                <div className="">
                    <Link to={'/first-appointment'} >
                        <Button text={"Hospital"} width={"40"} />
                    </Link>
                </div>
                <div className="">
                    <Button
                        text={"Online"}
                        textColor={"text-white"}
                        bg={"bg-primary100"}
                        width={"40"}
                    />
                </div>
            </div>
            <div className="flex flex-col mt-5 mx-5">
                <p className="text-primary100 font-bold" >Consultation will be done Online</p>
                <div className="mt-4">
                    <p className="font-medium text-xl">Select Clinic</p>
                    <select
                        className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        name="clinic"
                        onChange={handleChange}
                    >
                        <option value="">Select Clinic</option>
                        {clinicStore?.data?.clinics?.length > 0 ? clinicStore?.data?.clinics?.map((item, index) => {
                            return (
                                <option key={index} value={
                                    item.id
                                }>{item.name}</option>
                            )
                        }) : <option value="">loading....</option>}
                    </select>
                </div>
                <div className="mt-4">
                    <p className="font-medium text-xl">Choose Appointment date</p>
                    <input
                        type={"date"}
                        value={state.appt_date}
                        name="appt_date"
                        onChange={handleChange}
                        className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="  **** **** **** 2345"
                    />
                </div>
                <div className="mt-[1rem]">
                    <p className="text-[20px] font-semibold mb-2 ml-1">Choose Time</p>
                    <input
                        type={"time"}
                        name="appt_time"
                        onChange={handleChange}
                        className="border-2 border-green-200 focus:outline-none focus:border-green-500 bg-white w-full py-2 rounded-md mt-3"
                        placeholder="  **** **** **** 2345"
                    />
                </div>
                <div>
                    <label htmlFor="comment" className="text-[20px] font-semibold mb-2">
                        Notes
                    </label>
                    <div className="mt-2 p-4">
                        <textarea
                            rows={4}
                            name="notes"
                            onChange={handleChange}
                            id="notes"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md outline outline-1 outline-gray p-2"
                            defaultValue={""}
                            placeholder="Tell us how you feel"

                        />
                    </div>
                </div>
                <div className="flex flex-col justify-center mt-5">
                    <label className="text-[20px] font-semibold mb-2" >Consultation Fees</label>
                    <div className="flex justify-between">
                        <div className={`${selectAudio ? 'border-primary100' : ''} border-2 shadow-xl shadow-black-600 rounded-xl flex py-2 px-4 mb-4`} onClick={handleAudio} >
                            <div className="flex items-center rounded-full bg-red-50 p-4">
                                <BiPhoneCall className="text-primary100 text-2xl" />
                            </div>
                            <div className="flex flex-col ml-2">
                                <p className="text-[18px] font-semibold" >Voice Call</p>
                                <p className="text-green-500 font-bold"> <sub>&#8358; </sub>{ formatter.format(audio_amount) }</p>
                            </div>
                        </div>
                        <div className={`${selectVideo ? 'border-primary100' : ''} border-2 shadow-xl shadow-black-600 rounded-xl flex py-2 px-4 mb-4`} onClick={handleVideo}>
                            <div className="flex items-center rounded-full bg-red-50 p-4">
                                <FcVideoCall className="text-primary100 text-2xl" />
                            </div>
                            <div className="flex flex-col ml-2">
                                <p className="text-[18px] font-semibold" >Video Call</p>
                                <p className="text-green-500 font-bold"> <sub>&#8358; </sub>{  formatter.format(video_amount) }</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5 mb-5 w-100">
                    {loading ? <Loading /> :
                        <Button text={"Continue"} width={"full"} bg={'bg-primary100'} textColor={'text-white'} handleClick={handleSubmit} />
                    }

                </div>
            </div>
        </div>
    );
}
